/* header css */
.header-container .language-selector {
  transition: 0.5s;
}
/* footer css */
@media (max-width: 4000px) and (min-width: 1536px) {
  .container {
    max-width: 100% !important;
    margin-right: 0;
    margin-left: 0;

  }
}

@media (max-width: 1279px) and (min-width: 1024px) {
  /* .desktop-footerContent-container .specificScreen-width {
    justify-content: start !important;
  } */
  .desktop-footerContent-container .specificScreen-width {
    width: fit-content !important;
  }
  .desktop-footerContent-container {
    max-width: 1155px !important;
  }
}

@media (max-width: 4000px) and (min-width: 768px) {
  .right-box {
    padding-left: 10px;
  }
}

@media (max-width: 768px) and (min-width: 100px) {
  .right-box {
    padding-left: 0;
    padding-top: 10px;
  }
}

.header-link {
  position: absolute;
  top: -10px;
}

@media (max-width: 40000px) and (min-width: 1536px) {
  .top-header-container{
    padding-left: 150px !important;
    margin-left: 0 !important;
  }

  .header-image {
    height: 160px;
  }

  .header-link {
    top: -15px;
  }
}

@media (max-width: 1536px) and (min-width: 1279px) {
  .top-header-container{
    padding-left: 125px !important;
    margin-left: 0 !important;
  }

  .header-image {
    height: 127px;
  }

  .header-link {
    top: -15px;
  }
}

@media (max-width: 1279px) and (min-width: 1024px) {
  .top-header-container{
    padding-left: 100px !important;
    margin-left: 0 !important;
  }

  .header-image {
    height: 90px;
  }
}

@media (max-width: 1024px) and (min-width: 20px) {
  .top-header-container{
    padding-left: 85px !important;
    margin-left: 0 !important;
  }

  .header-image {
    height: 90px;
  }
}

@media (max-width: 768px) and (min-width: 20px) {
  .log-hass {
    display: flex;
  }
}

.bg-blank {
  --tw-bg-opacity: 1;
  background-color: #EBF7F6 !important;
}

.text-primary{
  color: rgb(57 130 132 / var(--tw-border-opacity)) !important;
}


