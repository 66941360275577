@import url("./layout.css"); /* tailwind configurations */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');
html{
  scroll-behavior: smooth;
}
body {
  zoom: 0.8;
}
/*body {*/
/*  margin: 0;*/
/*  font-family: 'Source',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  background-color: #F0F5F5;*/
/*}*/

/* css files imports */
/*@import url("./homepage.css");*/
/*@import url("./modal.css");*/
/*@import url("./loader.css");*/

